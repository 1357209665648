import { Auth } from "aws-amplify";

export default {
  login: ({ username, password }) => {
    return Auth.signIn(username, password)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(async () => {
        const token = (await Auth.currentSession()).idToken.jwtToken;
        console.log(await Auth.currentSession());
        localStorage.setItem("token", token);
        localStorage.setItem("permissions", "editor");
      })
      .catch((error) => {
        console.log("Erro fazendo login: ", error);
        throw new Error(error.message);
      });
  },
  logout: async () => {
    await Auth.signOut();
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      Auth.signOut();
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    if ((await Auth.currentSession()).idToken.jwtToken)
      return Promise.resolve(localStorage.getItem("token"));
    Auth.signOut();
    localStorage.removeItem("token");
    Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

import React from "react";
import {
  Datagrid,
  List,
  TextField,
  TextInput,
  DateField,
  useAuthenticated,
  ReferenceInput,
  SelectInput,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
} from "react-admin";

const UserFilters = [
  <TextInput label="Número" source="numero" alwaysOn />,
  <TextInput label="Observações" source="observacoes" />,
  <TextInput label="Ano" source="ano" />,
  <TextInput label="Ementa" source="ementa" />,
  <ReferenceInput
    label="Tipo do ato"
    source="tipo_ato"
    reference="enumeracoes_admin/tipo_documento"
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <TextInput label="Tag" source="tags" />,
];
const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton maxResults={10000} />
  </TopToolbar>
);

const DocumentList = (props) => {
  useAuthenticated();

  return (
    <List
      title="Documentos"
      bulkActionButtons={false}
      {...props}
      filters={UserFilters}
      actions={<ListActions />}
    >
      <Datagrid rowClick="edit">
        <TextField source="arquivo" label="Arquivo" />
        <TextField source="tipo_ato" label="Tipo do arquivo" />
        <TextField source="numero" label="Número" />
        <TextField source="ano" label="Ano" />
        <TextField source="observacoes" label="Observações" />
        <DateField source="data_modificacao" label="Data de modificação" />
        <TextField source="origem" label="Origem nova" />
        <TextField source="status" label="Status" />
        <TextField source="norma_principal" label="Norma Principal" />
      </Datagrid>
    </List>
  );
};

export default DocumentList;

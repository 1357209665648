import React from "react";
import "./App.scss";
import { Admin, Resource } from "react-admin";
import dataProvider from "./services/data-provider";
import authProvider from "./services/auth-provider";

import DocumentEdit from "./pages/Documents/document-edit";
import DocumentCreate from "./pages/Documents/document-create";
import DocumentList from "./pages/Documents/document-list";

import UserList from "./pages/User/user-list";
import UserShow from "./pages/User/user-show";

import PushNotificationList from "./pages/PushNotification/push-notification-list";
import PushNotificationShow from "./pages/PushNotification/push-notification-show";
import PushNotificationCreate from "./pages/PushNotification/push-notification-create";

import CategoriaList from "./pages/Categoria/categoria-list";
import CategoriaCreate from "./pages/Categoria/categoria-create";
import CategoriaEdit from "./pages/Categoria/categoria-edit";
import CategoriaShow from "./pages/Categoria/categoria-show";

import OrigemCreate from "./pages/Origem/origem-create";
import OrigemList from "./pages/Origem/origem-list";
import OrigemEdit from "./pages/Origem/origem-edit";
import OrigemShow from "./pages/Origem/origem-show";

import Theme from "./Theme";
import LoginPage from "./pages/LoginPage/LoginPage";
import ClientBusinessList from "./pages/ClientBusiness/client-business-list";
import ClientBusinessCreate from "./pages/ClientBusiness/client-business-create";
import ClientBusinessEdit from "./pages/ClientBusiness/client-business-edit";
import ClientBusinessShow from "./pages/ClientBusiness/client-business-show";
import NormasSemPdfList from "./pages/NormasSemPdf/normas_sem_pdf-list";
import UrlLinkList from "./pages/UrlLink/url-link-list";
import UrlLinkCreate from "./pages/UrlLink/url-link-create";
import UrlLinkEdit from "./pages/UrlLink/url-link-edit";
import UrlLinkShow from "./pages/UrlLink/url-linkshow";

function App() {
  return (
    <>
      <Admin
        theme={Theme}
        dataProvider={dataProvider()}
        authProvider={authProvider}
        loginPage={LoginPage}
      >
        <Resource
          name="admin/documento"
          options={{ label: "Documentos" }}
          list={DocumentList}
          create={DocumentCreate}
          edit={DocumentEdit}
        />
        <Resource
          name="usuario"
          options={{ label: "Usuários" }}
          list={UserList}
          show={UserShow}
        />
        <Resource
          name="push_notification"
          list={PushNotificationList}
          show={PushNotificationShow}
          create={PushNotificationCreate}
        />
        <Resource name="enumeracoes_admin/status_documento" />
        <Resource name="enumeracoes_admin/tipo_documento" />
        {/* <Resource name="enumeracoes_admin/origem_documento" /> */}
        <Resource name="documento/busca-numero" />
        <Resource
          name="admin/categoria"
          options={{ label: "Categorias" }}
          list={CategoriaList}
          create={CategoriaCreate}
          edit={CategoriaEdit}
          show={CategoriaShow}
        />
        <Resource
          name="admin/origem"
          options={{ label: "Origens" }}
          list={OrigemList}
          create={OrigemCreate}
          edit={OrigemEdit}
          show={OrigemShow}
        />
        <Resource
          name="admin/normas_sem_pdf"
          options={{ label: "Normas sem PDF" }}
          list={NormasSemPdfList}
        />

        <Resource
          name="admin/client_business"
          options={{ label: "Cliente Business" }}
          list={ClientBusinessList}
          create={ClientBusinessCreate}
          edit={ClientBusinessEdit}
          show={ClientBusinessShow}
        />
        <Resource
          name="admin/url_link"
          options={{ label: " Url Link" }}
          list={UrlLinkList}
          create={UrlLinkCreate}
          edit={UrlLinkEdit}
          show={UrlLinkShow}
        />
      </Admin>
    </>
  );
}

export default App;

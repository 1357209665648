import React from "react";
import {
  Create,
  SimpleForm,
  TextInput
} from "react-admin";

const OrigemCreate = props => {
  return (
    <Create title="Criar origem" {...props}>
        <SimpleForm redirect="list">
          <TextInput source="titulo" label="Título"/>
        </SimpleForm>
    </Create>
  );
};

export default OrigemCreate;

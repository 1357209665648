import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Datagrid, Filter, List, TextField, TextInput, DateField } from "react-admin";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Titulo" source="titulo" alwaysOn />
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  tableCell: {
    maxWidth: 200,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }
}));

const PushNotificationList = (props) => {
  const classes = useStyles();
  return (
    <List title="Push notifications" bulkActionButtons={false} {...props} filters={<UserFilter />}>
      <Datagrid rowClick="show">
        <TextField
          cellClassName={classes.tableCell}
          source="titulo"
          label="Titulo" />
        <TextField 
          cellClassName={classes.tableCell}
          source="mensagem"
          label="Mensagem" />
        <DateField source="criado_em" label="Data Criação" />
      </Datagrid>
    </List>
  );
};

export default PushNotificationList;

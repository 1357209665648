import React from "react";
import { CardActions, Datagrid, List, TextField } from "react-admin";

const UrlLinkList = (props) => {
  const NoneActions = (props) => <CardActions />;
  return (
    <List
      title="Link "
      bulkActionButtons={false}
      actions={<NoneActions />}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="url" label="Link" />
      </Datagrid>
    </List>
  );
};

export default UrlLinkList;

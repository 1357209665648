import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";

const UrlLinkShow = (props) => {
  return (
    <Show title="Visualizar Link" {...props}>
      <SimpleShowLayout>
        <TextField source="url" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UrlLinkShow;

import { createMuiTheme } from "@material-ui/core";
// import LatoRegular from './assets/fonts/Lato-Regular.ttf';

const Theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00917D",
      error: "#ffaaaa",
      highlight: "#f1f1f1",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
    },
  },
  // typography: {
  //   fontFamily: "Lato"
  // },
  // overrides: {
  //   MuiCssBaseline: {
  //     "@global": {
  //       "@font-face": [lato]
  //     }
  //   }
  // }
});

export default Theme;

import React from "react";
import {
  Datagrid,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceArrayField,
} from "react-admin";

const ClientBusinessShow = (props) => {
  return (
    <Show title="Visualizar Client Business" {...props}>
      <SimpleShowLayout>
        <TextField source="nome" />
        <TextField source="cor" />
        <TextField source="id" />
        <ReferenceArrayField
          source="usuarios_ids"
          reference="usuario"
          fullWidth
        >
          <Datagrid>
            <TextField label={"ID"} source="id" />
            <TextField label={"Email"} source="email" />
          </Datagrid>
        </ReferenceArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export default ClientBusinessShow;

import React from "react";
import { Edit, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin";

const UrlLinkEdit = (props) => {
  const UrlLinkEditToolBar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
  return (
    <Edit title="Editar link" {...props}>
      <SimpleForm toolbar={<UrlLinkEditToolBar />} redirect="list">
        <TextInput source="url" label="Link" />
      </SimpleForm>
    </Edit>
  );
};

export default UrlLinkEdit;

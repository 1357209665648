import React from "react";
import { TextField, Show, SimpleShowLayout } from "react-admin";

const UserShow = (props) => {
  console.log(props);

  return (
    <Show {...props} title="Visualizar usuário" actions={<div />}>
      <SimpleShowLayout>
        <TextField source="entity_id" label="ID" />
        <TextField source="nome" label="Nome" />
        <TextField source="cpf" label="CPF" />
        <TextField source="email" label="Email" />
        <TextField source="formacao" label="Formação" />
        <TextField source="nascimento" label="Nascimento" />
        <TextField source="area_atuacao.area_atuacao" label="Área de Atuação" />
        <TextField
          source="area_atuacao.local_atuacao.local"
          label="Local de Atuação"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;

import React from "react";
import {Datagrid, Filter, List, TextField, TextInput } from "react-admin";

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Título" source="titulo" alwaysOn/>
    </Filter>
);

const OrigemList = props => {
    return (
        <List title="Origens" bulkActionButtons={false} {...props} filters={<UserFilter />}>
            <Datagrid rowClick="edit">
                <TextField source="titulo" />
            </Datagrid>
        </List>
    )
};

export default OrigemList;

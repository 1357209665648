import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  Datagrid,
  ReferenceArrayField,
  TextField,
  useRecordContext,
  DeleteButton,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import CustomAddUserInput from "../../components/CustomAddUserInput";
import { API } from "aws-amplify";
import config from "../../config/constants";
import CustomModal from "../../components/CustomModal";

const ClientBusinessEdit = (props) => {
  const [newUserEmail, setNewUserEmail] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const handleClose = () => {
    setOpenModal(false);
  };

  const resetPasswordModalTitle = "Senha resetada com sucesso!";
  const resetPasswordModalContent = `Envie a nova senha para o usuário: ${newPassword}`;

  const useStyles = makeStyles((theme) => {
    return {
      root: {
        color: theme.palette.getContrastText(theme.palette.info.main),
        backgroundColor: theme.palette.info.main,
        "&:hover": {
          backgroundColor: theme.palette.info.dark,
        },
        marginLeft: "auto",
        "&:disabled": {
          backgroundColor: "gray",
          color: "white",
          cursor: "not-allowed",
        },
      },
    };
  });

  const classes = useStyles();

  const buttonStyles = {
    cursor: disableButton ? "not-allowed" : "pointer",
    backgroundColor: disableButton ? "gray" : "white",
    color: "white",
    border: "none",
    padding: "8px 16px",
    borderRadius: "4px",
    outline: "none",
  };

  const AddUserButton = (props) => {
    const record = useRecordContext(props);
    const handleAddUser = async (event) => {
      event.preventDefault();
      const data = {
        user_email: newUserEmail,
      };

      const clientBusinessId = record.entity_id;

      try {
        setDisableButton(true);
        await API.post(
          config.APIS.LEGALPHARMA_ADMIN,
          `admin/client_business/${clientBusinessId}/user`,
          {
            body: data,
          }
        );
        setNewUserEmail("");
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } catch (error) {
        setErrorMessage(error.response.data.message);
        console.error("Error adding user:", error.response);
      }
      setDisableButton(false);
    };
    return (
      <Button
        className={classes.root}
        onClick={handleAddUser}
        disabled={disableButton}
      >
        Adicionar usuário
      </Button>
    );
  };

  const ClientEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />

      <AddUserButton />

      <div style={{ marginLeft: "auto" }} />
      <DeleteButton />
    </Toolbar>
  );

  const RemoveButton = (props, _infos) => {
    const record = useRecordContext(props);

    const handleRemove = async (event) => {
      event.preventDefault();
      const clientBusinessId = props.infos.id;
      const data = {
        client_business_id: clientBusinessId,
        user_id: record.id,
      };

      try {
        setDisableButton(true);
        await API.del(
          config.APIS.LEGALPHARMA_ADMIN,
          `admin/client_business/${clientBusinessId}/user`,
          {
            body: data,
          }
        );

        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } catch (error) {
        console.error("Error removing user:", error.message);
      }
      setNewUserEmail("");
      setDisableButton(false);
    };
    return (
      <button
        onClick={handleRemove}
        styles={buttonStyles}
        disabled={disableButton}
      >
        Remover
      </button>
    );
  };

  const ResetPasswordButton = (props, _info) => {
    const record = useRecordContext(props);

    const handleResetPassword = async (event) => {
      event.preventDefault();
      const clientBusinessId = props.infos.id;
      const data = {
        user_id: record.id,
      };

      try {
        setDisableButton(true);
        const response = await API.post(
          config.APIS.LEGALPHARMA_ADMIN,
          `admin/client_business/${clientBusinessId}/reset_user_password`,
          {
            body: data,
          }
        );

        setOpenModal(true);
        setNewPassword(response.data.new_password);
      } catch (error) {
        console.error("Error reseting user password:", error.message);
      }
      setNewUserEmail("");
      setDisableButton(false);
    };
    return (
      <button
        onClick={handleResetPassword}
        styles={buttonStyles}
        disabled={disableButton}
      >
        Resetar senha
      </button>
    );
  };

  return (
    <Edit title="Editar usuario" {...props}>
      <SimpleForm toolbar={<ClientEditToolbar />}>
        <CustomModal
          open={openModal}
          handleClose={handleClose}
          newPassword={newPassword}
          title={resetPasswordModalTitle}
          content={resetPasswordModalContent}
        />
        <TextInput source="nome" label="Cliente" />
        <ColorInput source="cor" />
        <div style={{ marginBottom: "20px" }} />
        <TextInput source="url_link" label="Site personalizado" />
        <ReferenceArrayField
          source="usuarios_ids"
          reference="usuario"
          fullWidth
        >
          <Datagrid>
            <TextField label={"ID"} source="id" />
            <TextField label={"Email"} source="email" />
            <ResetPasswordButton infos={props} />
            <RemoveButton infos={props} />
          </Datagrid>
        </ReferenceArrayField>
        <CustomAddUserInput
          newUserEmail={newUserEmail}
          setNewUserEmail={setNewUserEmail}
          label="Email"
        />
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
      </SimpleForm>
    </Edit>
  );
};

export default ClientBusinessEdit;

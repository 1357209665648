import React from "react";
import { Datagrid, Filter, List, TextField, TextInput } from "react-admin";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
  </Filter>
);

const UserList = (props) => {
  return (
    <List title="Usuários" bulkActionButtons={false} {...props} filters={<UserFilter />}>
      <Datagrid rowClick="show">
        <TextField source="nome" label="Nome" />
        <TextField source="cpf" label="CPF" />
        <TextField source="email" label="Email" />
        <TextField source="formacao" label="Formação" />
        <TextField source="nascimento" label="Nascimento" />
        <TextField source="area_atuacao.area_atuacao" label="Área de Atuação" />
        <TextField
          source="area_atuacao.local_atuacao.local"
          label="Local de Atuação"
        />
      </Datagrid>
    </List>
  );
};

export default UserList;

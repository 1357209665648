import React from "react";

import { Create, SimpleForm, TextInput, maxLength } from "react-admin";

const PushNotificationCreate = (props) => {
  return (
    <Create {...props} title="Criar push notification">
      <SimpleForm redirect="list">
        <TextInput
          source="titulo"
          label="Titulo"
          validate={maxLength(60)}
          multiline
          fullWidth
        />
        <TextInput
          source="mensagem"
          label="Mensagem"
          validate={maxLength(240)}
          multiline
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default PushNotificationCreate;

import React, { useEffect, useState } from "react";

import {
  Edit,
  SimpleForm,
  useAuthenticated,
  SelectInput,
  ReferenceInput,
  TextInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
  AutocompleteArrayInput,
  BooleanInput,
} from "react-admin";

import Upload from "../../components/Upload/Upload";
import {
  getUrDocumentlUpload,
  getUrRelatedDocumentlUpload,
} from "../../services/file.service";
import { useField } from "react-final-form";
import { API } from "aws-amplify";
import config from "../../config/constants";

const choicesTipoModificacao = [
  { id: "ALTERA", name: "Altera" },
  { id: "REVOGA", name: "Revoga" },
  { id: "REGULAMENTA", name: "Regulamenta" },
  { id: "REPUBLICA", name: "Republica" },
  { id: "SUSPENDE", name: "Suspende" },
];

const optionRendererDocument = (choice) => {
  if (!choice) return "";
  return `${choice.tipo_ato} ${choice.numero} / ${choice.ano}`;
};

const edit = true;

const AutoCompleteDocument = (props) => {
  const source = props.source + ".documento";
  const documento = useField(source).input.value;
  return (
    <ReferenceInput
      label="Documento"
      source={source}
      reference="admin/documento"
      perPage={100}
      filterToQuery={(query) =>
        query ? { numero: query } : { entity_id: documento }
      }
    >
      <AutocompleteInput optionText={optionRendererDocument} fullWidth />
    </ReferenceInput>
  );
};

const DocumentEdit = (props) => {
  const [choices, setChoices] = useState([]);
  useAuthenticated();
  useEffect(() => {
    API.get(config.APIS.LEGALPHARMA_ADMIN, "categoria").then((res) => {
      setChoices(
        res.data
          .map((x) => x.etiquetas)
          .flat()
          .map((x) => ({ tags: x.titulo }))
      );
    });
  }, []);

  const CustomSelectInput = ({ optionText, ...rest }) => {
    const { choices, input } = rest;

    const SelectedOrigem = choices
      .filter((item) => item.titulo === input.value.titulo)
      .shift();
    let initialChoice = typeof input.value === "object";

    return (
      <SelectInput
        optionText={optionText}
        {...rest}
        {...(initialChoice && { value: SelectedOrigem.id })}
      />
    );
  };

  const CustomAutocompleteArrayInput = ({ optionText, ...rest }) => {
    const { choices } = rest;

    return (
      <AutocompleteArrayInput
        label="Tags"
        source="tags"
        choices={choices}
        fullWidth
        optionText="tags"
        optionValue="tags"
        key="tags"
      />
    );
  };

  return (
    <Edit title="Editar documento" {...props}>
      <SimpleForm redirect="list" defaultValues={{ date: new Date() }}>
        <Upload
          label="Arquivo"
          source="url_do_documento"
          getUrl={getUrDocumentlUpload}
          edit={edit}
        />
        <BooleanInput
          source="norma_principal"
          label="Norma Principal"
          fullWidth
        />
        <ReferenceInput
          label="Status"
          source="status"
          reference="enumeracoes_admin/status_documento"
        >
          <SelectInput optionText="name" fullWidth />
        </ReferenceInput>
        <ReferenceInput
          label="Tipo do ato"
          source="tipo_ato"
          reference="enumeracoes_admin/tipo_documento"
        >
          <SelectInput optionText="name" fullWidth />
        </ReferenceInput>
        <ReferenceInput
          label="Origem"
          source="origem_id"
          reference="admin/origem"
          perPage={100}
        >
          <CustomSelectInput optionText="titulo" />
        </ReferenceInput>
        <TextInput
          source="observacoes"
          multiline
          label="Observação (texto interno)"
          fullWidth
        />
        <DateInput
          source="data_publicacao"
          label="Data de publicação"
          fullWidth
        />
        <TextInput
          source="comentarios"
          multiline
          label="Comentarios (Aparece no App)"
          fullWidth
        />
        <TextInput source="ementa" multiline label="Ementa" fullWidth />
        <TextInput source="ano" label="Ano" fullWidth />
        <TextInput source="numero" label="Número" fullWidth />
        <ArrayInput source="modificacoes" label="Modificações">
          <SimpleFormIterator>
            <SelectInput
              label="Tipo"
              source="tipo"
              choices={choicesTipoModificacao}
              fullWidth
            />
            <AutoCompleteDocument />
          </SimpleFormIterator>
        </ArrayInput>
        <CustomAutocompleteArrayInput
          label="Tags"
          source="tags"
          choices={choices}
          fullWidth
          optionText="tags"
          optionValue="tags"
        />
        <ArrayInput
          source="documentos_relacionados"
          label="Documentos Relacionados"
        >
          <SimpleFormIterator>
            <TextInput source="nome" label="Nome" />
            <Upload
              label="Arquivo"
              source="docid"
              getUrl={getUrRelatedDocumentlUpload}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default DocumentEdit;

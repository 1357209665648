import React from "react";
import {Datagrid, Filter, List, TextField, TextInput, ArrayField, SingleFieldList, ChipField } from "react-admin";
import { ColorField } from "react-admin-color-input"

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Título" source="titulo" alwaysOn/>
    </Filter>
);

const CategoriaList = props => {
    return (
        <List title="Categorias" bulkActionButtons={false} {...props} filters={<UserFilter />}>
            <Datagrid rowClick="edit">
                <TextField source="titulo" />
                <ColorField source="cor" label="Cor"/>
                <ArrayField source="etiquetas"><SingleFieldList><ChipField source="titulo" /></SingleFieldList></ArrayField>
            </Datagrid>
        </List>
    )
};

export default CategoriaList;

import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";

const OrigemShow = props => {
  return (
    <Show title="Visualizar origem" {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="titulo" />
      </SimpleShowLayout>
    </Show>
  );
};

export default OrigemShow;

import React from "react";
import { Datagrid, Filter, List, TextField, TextInput } from "react-admin";
import { ColorField } from "react-admin-color-input";

const ClientFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Cliente" source="titulo" alwaysOn />
  </Filter>
);

const ClientBusinessList = (props) => {
  return (
    <List
      title="Cliente Business"
      bulkActionButtons={false}
      {...props}
      filters={<ClientFilter />}
    >
      <Datagrid rowClick="edit">
        <TextField source="nome" label="Cliente" />
        <ColorField source="cor" label="Cor" />
      </Datagrid>
    </List>
  );
};

export default ClientBusinessList;

import React from "react";
import { Edit, SimpleForm, TextInput, SaveButton, Toolbar } from "react-admin";

const OrigemEdit = props => {
  const UserEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
  );

  return (
    <Edit title="Editar origem" {...props}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextInput source="titulo" />        
      </SimpleForm>
    </Edit>
  )
};

export default OrigemEdit;

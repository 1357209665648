import React from "react";
import { ArrayField, Datagrid, Show, SimpleShowLayout, FunctionField, TextField } from "react-admin";
import config from "../../config/constants";

const CategoriaShow = props => {
  const FilenameField = props => <FunctionField 
    {...props}
    render={({ icone }) => (
      <img
        src={`${config.CLOUD_FRONT}/icones/${icone}.png`} 
        title="image" 
        style={{ height: 40, width: 40, backgroundColor: '#4a4a4a75', padding: 8, borderRadius: '50%'}}
        alt="Icone"
      />
    )} 
  />;  

  return (
    <Show title="Visualizar categoria" {...props}>
      <SimpleShowLayout>
        <TextField source="titulo" />
        <TextField source="cor" />
        <TextField source="id" />
        <ArrayField source="etiquetas">
          <Datagrid>
            <TextField source="titulo" />
            <FilenameField source="icone" />
            <TextField source="icone" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export default CategoriaShow;

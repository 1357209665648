import React from "react";
import {
  ArrayInput,
  Create,
  SimpleForm,
  SimpleFormIterator,
  TextInput
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import Upload from "../../components/Upload/Upload";
import { getUrIconelUpload } from "../../services/file.service";

const CategoriaCreate = props => {
  return (
    <Create title="Criar categoria" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="titulo" label="Título"/>
        <ColorInput source="cor" />
        <ArrayInput source="etiquetas">
        <SimpleFormIterator>
            <TextInput source="titulo" label="Título"/>
            <Upload
              label="icone"
              source="icone"
              getUrl={getUrIconelUpload}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
};

export default CategoriaCreate;

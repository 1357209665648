import React from "react";
import { TextField, DateField, Show, SimpleShowLayout } from "react-admin";

const PushNotificationShow = (props) => {
  return (
    <Show {...props} title="Visualizar push notification" actions={<div />}>
      <SimpleShowLayout>
        <TextField source="entity_id" label="ID" />
        <TextField source="titulo" label="Titulo" />
        <TextField source="mensagem" label="Mensagem" />
        <DateField source="criado_em" label="Data criação"/> 
      </SimpleShowLayout>
    </Show>
  );
};

export default PushNotificationShow;

import React from "react";
import { ArrayInput, Edit, SimpleForm, SimpleFormIterator, TextInput, SaveButton, Toolbar } from "react-admin";
import { ColorInput } from "react-admin-color-input";
import Upload from "../../components/Upload/Upload";
import { getUrIconelUpload } from "../../services/file.service";

const CategoriaEdit = props => {
  const UserEditToolbar = props => (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit title="Editar categoria" {...props}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextInput source="titulo" label="Título"/>
        <ColorInput source="cor" />
        <ArrayInput source="etiquetas">
          <SimpleFormIterator>
            <TextInput source="titulo" label="Título"/>
            <Upload
              label="icone"
              source="icone"
              getUrl={getUrIconelUpload}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
};

export default CategoriaEdit;

import React from "react";
import { Login } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  login: {
    background: "radial-gradient(circle, #8bcbc2 0%, #00917D 100%)!important",
  },
});

const LoginPage = () => {
  const classes = useStyles();
  return <Login className={classes.login} backgroundImage="" />;
};

export default LoginPage;

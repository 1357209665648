const APIS = {
  LEGALPHARMA: "legalpharma-api",
  LEGALPHARMA_PUBLIC: "legalpharma-public-api",
  LEGALPHARMA_ADMIN: "legalpharma-admin-api",
};

const STAGES = {
  loc: {
    COGNITO: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_BPCFOzuVv",
      APP_CLIENT_ID: "27g3ocae5f6tjtadt79rapbit6",
    },
    APIS,
    DOMAIN: "https://k9jzcca0z7.execute-api.us-east-1.amazonaws.com/dev/",
    DOMAIN_ADMIN: "http://localhost:8000/",
    CLOUD_FRONT: "https://d3mbke9sgs53ku.cloudfront.net",
  },
  dev: {
    COGNITO: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_BPCFOzuVv",
      APP_CLIENT_ID: "27g3ocae5f6tjtadt79rapbit6",
    },
    APIS,
    DOMAIN: "https://k9jzcca0z7.execute-api.us-east-1.amazonaws.com/dev/",
    DOMAIN_ADMIN: "https://k9jzcca0z7.execute-api.us-east-1.amazonaws.com/dev/",
    CLOUD_FRONT: "https://d3mbke9sgs53ku.cloudfront.net",
  },
  stg: {
    COGNITO: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_y0UFb2JFo",
      APP_CLIENT_ID: "7u1maoijabf86fepnep49dekdh",
    },
    APIS,
    DOMAIN: "https://w61ggcs451.execute-api.us-east-1.amazonaws.com/stg/",
    DOMAIN_ADMIN: "https://w61ggcs451.execute-api.us-east-1.amazonaws.com/stg/",
    CLOUD_FRONT: "https://d1n47ri1hs0gpr.cloudfront.net",
  },
  prd: {
    COGNITO: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_LhvEZOnd0",
      APP_CLIENT_ID: "7ndf22ns7ie0gi2vgmp0otbro7",
    },
    APIS,
    DOMAIN: "https://t67w8mvtr1.execute-api.us-east-1.amazonaws.com/prd_v3/",
    DOMAIN_ADMIN:
      "https://t67w8mvtr1.execute-api.us-east-1.amazonaws.com/prd_v3/",
    CLOUD_FRONT: "https://d2cml97c6rw4is.cloudfront.net",
  },
};

const env = process.env.REACT_APP_STAGE || "dev";
// const env = "loc"
const config = STAGES[env];

export default config;

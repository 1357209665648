import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

const UrlLinkCreate = (props) => {
  return (
    <Create title="Criar link" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="url" label="Link" />
      </SimpleForm>
    </Create>
  );
};

export default UrlLinkCreate;

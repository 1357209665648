import React from "react";
import "./styles.css";

const CustomAddUserInput = ({ newUserEmail, setNewUserEmail, label }) => {
  const handleUserEmailChange = (event) => {
    setNewUserEmail(event.target.value);
  };

  return (
    <>
      <spam className="label">{label}</spam>
      <div>
        <input
          className="custom-input"
          type="text"
          label="Novo email de usuario"
          value={newUserEmail}
          onChange={handleUserEmailChange}
        />
      </div>
    </>
  );
};

export default CustomAddUserInput;

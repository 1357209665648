import React from "react";
import { Datagrid, List, TextField } from "react-admin";

const NormasSemPdfList = props => {
  return (
    <List
      {...props}
      title="Normas sem PDF"
      bulkActionButtons={false}
      perPage={5000}
      pagination={false}>
      <Datagrid>
        <TextField source="origem" label="Origem" sortable={false}/>
        <TextField source="tipo_ato" label="Tipo" sortable={false}/>
        <TextField source="numero" label="Número" sortable={false}/>
        <TextField source="ano" label="Ano" sortable={false}/>
        <TextField source="arquivo" label="Arquivo" sortable={false}/>
        <TextField source="versao" label="Versão" sortable={false}/>
        <TextField source="data_importacao" label="Importado em" sortable={false}/>
        <TextField source="data_modificacao" label="Modificado em" sortable={false}/>
        <TextField source="entity_id" label="ID" sortable={false}/>
      </Datagrid>
    </List>
  );
};

export default NormasSemPdfList;
